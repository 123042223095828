<script>
import { mapState } from 'vuex'
import CrossIcon from '@/assets/svgs/cross.vue'

export default {
  components: { CrossIcon },
  data() {
    return {
      productss: [
        {
          id: '12cdihasd',
          img: require('../../../assets/images/page2/bottle.png'),
          title: 'Eau minérale naturelle',
          size: 'pack 6 / 1.5',
          quantity: 2,
          price: 34,
        },
        {
          id: '12cdihzxd',
          img: require('../../../assets/images/page2/bottle.png'),
          title: 'Eau minérale naturelle',
          size: 'pack 6 / 1.5',
          quantity: 2,
          price: 34,
        },
        {
          id: '12cdivvbasd',
          img: require('../../../assets/images/page2/bottle.png'),
          title: 'Eau minérale naturelle',
          size: 'pack 6 / 1.5',
          quantity: 2,
          price: 34,
        },
      ],
      img: require('../../../assets/images/page2/bottle.png'),
      orders: [],
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    }
  },

  computed: {
    ...mapState(['customer']),
  },
  watch: {
    customer(user) {
      const orders = user.orders
      if (orders) {
        const userorders = orders.filter((order) => {
          if (order?.orderNumber?.startsWith('SH'))
            return false

          const date = new Date(order.history[0].date)
          const monthnumber = date.getMonth()
          const month = this.months[monthnumber]
          const year = date.getFullYear()
          const day = date.getDate()

          order.history = order.history.filter((history) => {
            return (history.date = `${month} ${day}, ${year}`)
          })

          order.totalAmount = 0
          order.orderItems.forEach((item) => {
            order.totalAmount += item.price
          })

          return order
        })
        this.orders = userorders
      }
    },
  },
  mounted() {
    const orders = this.customer.orders
    if (orders) {
      const userorders = orders.filter((order) => {
        if (order?.orderNumber?.startsWith('SH'))
          return false
        const date = new Date(order.history[0].date)
        const monthnumber = date.getMonth()
        const month = this.months[monthnumber]
        const year = date.getFullYear()
        const day = date.getDate()

        order.history = order.history.filter((history) => {
          return (history.date = `${month} ${day}, ${year}`)
        })

        order.totalAmount = 0
        order.orderItems.forEach((item) => {
          order.totalAmount += item.price
        })

        return order
      })
      this.orders = userorders
    }
  },
}
</script>

<template>
  <div v-if="orders.length > 0" class="page2__right">
    <perfect-scrollbar>
      <div v-for="order in orders" :key="order.id" class="page2__right__productContainer">
        <div class="d-flex flex-column flex-sm-row">
          <div
            class="
              page2__right__productContainer__imgContainer
              position-relative
            "
          >
            <div
              class="
                page2__right__productContainer__imgContainer__img
                d-sm-block d-flex
              "
            >
              <img :src="img" alt="">
              <div
                class="
                  page2__right__productContainer__details
                  d-sm-none d-block
                "
              >
                <div class="page2__right__productContainer__details__header">
                  <h1
                    class="
                      page2__right__productContainer__details__header__title
                      text-uppercase
                      pb-2
                    "
                  >
                    {{ order.orderItems.length }} Produits
                  </h1>
                  <p
                    v-for="item in order.orderItems" :key="item.product.id" class="
                      pb-1
                      page2__right__productContainer__details__header__size
                      text-uppercase
                    "
                  >
                    * {{ item.product.nameFrench }}
                    <span class="text-bold">(<small>x</small>{{ item.quantity }} )</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-sm-block d-none page2__right__productContainer__details">
            <div class="page2__right__productContainer__details__header">
              <h1
                class="
                  page2__right__productContainer__details__header__title
                  text-uppercase
                  pb-2
                "
              >
                {{ order.orderItems.length }} Produit(s)
              </h1>
              <p
                v-for="item in order.orderItems" :key="item.product.id" class="
                  pb-1
                  page2__right__productContainer__details__header__size
                  text-uppercase
                "
              >
                * {{ item.product.nameFrench }}
                <span class="text-bold">(<small>x</small>{{ item.quantity }} )</span>
              </p>
            </div>
          </div>
          <div>
            <p
              class="
                page2__right__productContainer__price
                d-flex
                align-items-baseline
              "
            >
              <span
                class="
                  text-primary
                  page2__right__productContainer__price__text
                  align-self-center
                "
              >TOTAL TTC</span>
              <span class="ml-2 mr-1">{{ order.totalAmount }}</span>
              <span class="page2__right__productContainer__price__currency">Dh</span>
            </p>
            <div class="page2__orderDetails pt-4">
              <p class="page2__orderDetails__ordernumber pb-1">
                <span class="page2__orderDetails__ordernumber__text">Commande N° :
                </span>
                <span class="page2__orderDetails__ordernumber__number">{{
                  order.orderNumber
                }}</span>
              </p>
              <p class="page2__orderDetails__orderdate pb-1">
                <span class="page2__orderDetails__orderdate__text">Date de commande :</span>
                <span class="pl-1 page2__orderDetails__orderdate__date">
                  {{ order.history[0].date }}
                </span>
              </p>
              <p class="page2__orderDetails__orderstatus pb-1">
                <span class="page2__orderDetails__orderstatus__text">Statut :</span>
                <span class="pl-1 page2__orderDetails__orderstatus__status">{{
                  order.history[order.history.length - 1].status
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <!-- <div
      v-else
      class=" h-100  d-flex justify-content-center align-items-center"
    >
      <h3 class="text-center text-primary">You Don't Have Any Past Orders</h3>
    </div> -->
</template>

<style lang="scss" scoped>

</style>

<!-- <div
          class="d-flex justify-content-between align-items-center page2__right__productContainer__details__quantitybtnContainer"
        >
          <button
            class=" btn page2__right__productContainer__details__quantitybtn"
          >
            -
          </button>
          <p
            class="align-self-start page2__right__productContainer__details__quantity"
          >
            {{ product.quantity }}
          </p>
          <button
            class=" btn page2__right__productContainer__details__quantitybtn"
          >
            +
          </button>
        </div> -->
