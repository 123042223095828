<script>
import Left from '@/components/pages/page2/Left.vue'
import Right from '@/components/pages/page2/Right.vue'
export default {
  components: { Left, Right },
}
</script>

<template>
  <div class="page2__headerContainer">
    <div class="page2 page__bg">
      <div class="page2__container">
        <div class="row page2__row justify-content-xl-between">
          <div class="col-xl-5 col-12 page2__mobileCol">
            <Left />
          </div>
          <div class="col-7 d-xl-block d-none">
            <Right />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
