<script>
import Page2 from '@/components/pages/page2.vue'

export default { components: { Page2 } }
</script>

<template>
  <div>
    <Page2 />
  </div>
</template>

<style lang="scss" scoped>

</style>
